
function About() {
  return (
    <>
      <div style={{ marginTop: "10rem" }} />
      <p className={"topic text-bold text-left"}>
        About Us
      </p>
      <br />
      <p style={{ lineHeight: 2 }}>
        Founded in 2024, IC21 Tech Co., Ltd. was established on a foundation of
        over 8 years of extensive experience in the cybersecurity field. Our
        journey began with a clear realization: many companies and clients in
        Thailand were in dire need of a deeper understanding and implementation
        of cybersecurity practices. We recognized this gap and made it our
        mission to provide professional cybersecurity services and knowledge to
        empower businesses of all sizes. Our dedication to enhancing security
        and educating our clients drives everything we do.
      </p>
      <br />
      <p className={"mt-1"} style={{ lineHeight: 2 }}>
        Our vision is to deliver professional cybersecurity services tailored to
        the unique needs of businesses of every size. We understand that every
        organization operates under different constraints, so our solutions are
        designed to be flexible, accommodating various budgets and conditions.
        We are committed to making cybersecurity accessible and essential for
        everyone, from large corporations to individuals, because we believe
        that cybersecurity is not just for IT professionals but is crucial for
        everyone.
      </p>
      <br />
      <p className={"mt-1"} style={{ lineHeight: 2 }}>
        Enhance Security: We aim to make our clients more secure from the
        ever-evolving landscape of cybersecurity threats. Our services are
        designed to protect your digital assets and ensure your peace of mind.
      </p>
      <br />
      <p className={"mt-1"} style={{ lineHeight: 2 }}>
        Educate and Empower: We are dedicated to educating our clients and the
        broader community about cybersecurity. By sharing our knowledge, we
        empower businesses and individuals to recognize and mitigate potential
        threats.
      </p>
      <br />
      <p className={"mt-1"} style={{ lineHeight: 2 }}>
        Raise Awareness: We strive to make cybersecurity awareness a fundamental
        aspect of everyday life. Whether you're a business leader or an ordinary
        person, understanding cybersecurity is essential to protecting what
        matters most."
      </p>
      <br />
      <p className={"mt-1"} style={{ lineHeight: 2 }}>
        IC21 Tech Co., Ltd. is dedicated to providing top-tier cybersecurity
        solutions and education. Our team’s expertise, coupled with our
        commitment to personalized service, ensures that we can meet the unique
        needs of each client. Whether you’re looking to strengthen your IT
        infrastructure or educate your team on cybersecurity best practices, we
        have the experience and knowledge to help you achieve your goals.
      </p>
    </>
  );
}

export default About;
