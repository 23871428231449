import React from "react";
import {
  Card,
  CardContent,
  CardActions,
  Button,
  Typography,
} from "@mui/material";

const Privacy = () => {
  const handleAccept = () => {
    console.log("Accepted");
  };

  const handleDecline = () => {
    console.log("Declined");
  };

  return (
    <div>
      <Typography variant="h4" align="center" gutterBottom>
        Privacy Policy & Terms of Service
      </Typography>
      <Card
        sx={{
          maxWidth: 800,
          margin: "20px auto",
          padding: "20px",
          backgroundColor: "#2c2c2c",
          color: "white",
        }}
      >
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Privacy Policy
          </Typography>
          <Typography variant="body2" gutterBottom>
            Effective Date: 01/09/2024
          </Typography>
          <Typography variant="body2" paragraph>
            IC21 Tech Co., Ltd. ("we," "us," or "our") operates the website https://ic21.tech (the "Site") and provides related services (collectively, the "Services"). This Privacy Policy explains how we collect, use, disclose, and protect your information when you visit our Site or use our Services.
          </Typography>

          <Typography variant="h6" gutterBottom>
            1. Information We Collect
          </Typography>
          <Typography variant="body2" paragraph>
            We collect several types of information to provide and improve our Services to you:
          </Typography>
          <Typography variant="body2" paragraph>
            - Personal Data: While using our Services, we may ask you to provide certain personal information that can be used to contact or identify you, such as your name, email address, phone number, and payment information.
          </Typography>
          <Typography variant="body2" paragraph>
            - Usage Data: We may collect information about how the Site and Services are accessed and used. This may include your IP address, browser type, pages visited, and time spent on the site.
          </Typography>
          <Typography variant="body2" paragraph>
            - Cookies and Tracking Data: We use cookies and similar tracking technologies to track the activity on our Site and store certain information.
          </Typography>

          <Typography variant="h6" gutterBottom>
            2. Use of Your Information
          </Typography>
          <Typography variant="body2" paragraph>
            We use the collected data for the following purposes:
          </Typography>
          <Typography variant="body2" paragraph>
            - To provide, maintain, and improve our Services
          </Typography>
          <Typography variant="body2" paragraph>
            - To notify you about changes to our Services
          </Typography>
          <Typography variant="body2" paragraph>
            - To process transactions and manage payments
          </Typography>
          <Typography variant="body2" paragraph>
            - To provide customer support
          </Typography>
          <Typography variant="body2" paragraph>
            - To monitor the usage of the Site
          </Typography>
          <Typography variant="body2" paragraph>
            - To detect and prevent technical issues and potential security threats
          </Typography>

          <Typography variant="h6" gutterBottom>
            3. Sharing of Your Information
          </Typography>
          <Typography variant="body2" paragraph>
            We may share your information with third parties in the following cases:
          </Typography>
          <Typography variant="body2" paragraph>
            - With your consent
          </Typography>
          <Typography variant="body2" paragraph>
            - For legal reasons (e.g., complying with a legal obligation)
          </Typography>
          <Typography variant="body2" paragraph>
            - To prevent fraud or address security issues
          </Typography>
          <Typography variant="body2" paragraph>
            - With service providers who assist us in operating the Site or providing our Services
          </Typography>

          <Typography variant="h6" gutterBottom>
            4. Data Security
          </Typography>
          <Typography variant="body2" paragraph>
            We take reasonable measures to protect the information we collect from loss, misuse, unauthorized access, or disclosure. However, no method of transmission over the Internet or electronic storage is 100% secure.
          </Typography>

          <Typography variant="h6" gutterBottom>
            5. Your Data Protection Rights
          </Typography>
          <Typography variant="body2" paragraph>
            Depending on your location, you may have the right to access, correct, update, or request deletion of your personal data. If you wish to exercise these rights, please contact us at [insert contact email].
          </Typography>

          <Typography variant="h6" gutterBottom>
            6. Changes to This Privacy Policy
          </Typography>
          <Typography variant="body2" paragraph>
            We may update our Privacy Policy from time to time. Any changes will be posted on this page with the "Effective Date" at the top. We encourage you to review this Privacy Policy periodically.
          </Typography>

          <Typography variant="h6" gutterBottom>
            7. Contact Us
          </Typography>
          <Typography variant="body2" paragraph>
            If you have any questions or concerns about this Privacy Policy, please contact us at:
          </Typography>
          <Typography variant="body2" paragraph>
            - Email: anapat@c1inton.sh
          </Typography>
          <Typography variant="body2" paragraph>
            - Address: IC21 Tech Co., Ltd., 124 Sukhapiban 5 Road, Tha Raeng Subdistrict, Bang Khen District, Bangkok.
          </Typography>

          <Typography variant="h6" gutterBottom>
            Terms of Service
          </Typography>
          <Typography variant="body2" paragraph>
            Effective Date: 01/09/2024
          </Typography>
          <Typography variant="body2" paragraph>
            Welcome to IC21 Tech Co., Ltd. ("we," "us," or "our"). These Terms of Service ("Terms") govern your use of our website [insert website URL] (the "Site") and any related services (collectively, the "Services"). By using our Site or Services, you agree to these Terms.
          </Typography>

          <Typography variant="h6" gutterBottom>
            1. Acceptance of Terms
          </Typography>
          <Typography variant="body2" paragraph>
            By accessing or using the Services, you agree to comply with these Terms. If you do not agree, you may not use our Services.
          </Typography>

          <Typography variant="h6" gutterBottom>
            2. Use of Services
          </Typography>
          <Typography variant="body2" paragraph>
            You agree to use our Services only for lawful purposes and in accordance with these Terms. You will not:
          </Typography>
          <Typography variant="body2" paragraph>
            - Use the Services in any way that violates any applicable law or regulation
          </Typography>
          <Typography variant="body2" paragraph>
            - Use the Services for fraudulent purposes
          </Typography>
          <Typography variant="body2" paragraph>
            - Interfere with or disrupt the integrity or performance of the Services
          </Typography>

          <Typography variant="h6" gutterBottom>
            3. User Accounts
          </Typography>
          <Typography variant="body2" paragraph>
            If you create an account with us, you are responsible for maintaining the confidentiality of your login credentials and for any activities that occur under your account. You agree to notify us immediately of any unauthorized use of your account.
          </Typography>

          <Typography variant="h6" gutterBottom>
            4. Intellectual Property
          </Typography>
          <Typography variant="body2" paragraph>
            The content, design, and technology used in our Services are the property of IC21 Tech Co., Ltd. or our licensors and are protected by copyright, trademark, and other intellectual property laws. You agree not to copy, distribute, or create derivative works from any content found on the Site without our express permission.
          </Typography>

          <Typography variant="h6" gutterBottom>
            5. Limitation of Liability
          </Typography>
          <Typography variant="body2" paragraph>
            We are not liable for any damages, including but not limited to, direct, indirect, incidental, or consequential damages, arising out of your use of or inability to use the Services.
          </Typography>

          <Typography variant="h6" gutterBottom>
            6. Termination
          </Typography>
          <Typography variant="body2" paragraph>
            We reserve the right to terminate or suspend access to our Services immediately, without prior notice or liability, for any reason, including violation of these Terms.
          </Typography>

          <Typography variant="h6" gutterBottom>
            7. Changes to These Terms
          </Typography>
          <Typography variant="body2" paragraph>
            We reserve the right to update or modify these Terms at any time. If we make changes, we will notify you by posting the revised Terms on the Site. Your continued use of the Services constitutes acceptance of the updated Terms.
          </Typography>

          <Typography variant="h6" gutterBottom>
            8. Governing Law
          </Typography>
          <Typography variant="body2" paragraph>
          These Terms are governed by and construed in accordance with the laws of the Kingdom of Thailand. Any disputes arising from these Terms will be resolved in the courts of Thailand.
          </Typography>

          <Typography variant="h6" gutterBottom>
            9. Contact Us
          </Typography>
          <Typography variant="body2" paragraph>
            If you have any questions or concerns about these Terms, please contact us at:
          </Typography>
          <Typography variant="body2" paragraph>
            - Email: anapat@c1inton.sh
          </Typography>
          <Typography variant="body2" paragraph>
            - Address: IC21 Tech Co., Ltd., 124 Sukhapiban 5 Road, Tha Raeng Subdistrict, Bang Khen District, Bangkok.
          </Typography>
        </CardContent>
        <CardActions style={{ justifyContent: "flex-end" }}>
          <Button
            variant="contained"
            className={"btn-theme-2"}
            onClick={handleAccept}
          >
            I Do Not Accept
          </Button>
          <Button
            variant="outlined"
            className={"btn-theme-1"}
            onClick={handleDecline}
          >
            I Accept
          </Button>
        </CardActions>
      </Card>
    </div>
  );
};

export default Privacy;
