// import { Button } from "@mui/material";
import { Card, CardContent, Typography, Box } from "@mui/material";
import Grid from "@mui/material/Grid2";
import img1 from "../assets/ex/Frame1.png";
import img2 from "../assets/ex/Frame2.png";
import img3 from "../assets/ex/Frame5.png";
import img4 from "../assets/ex/Frame4.png";
import img5 from "../assets/ex/Frame3.png";
import img6 from "../assets/ex/Frame6.png";
import { useTheme } from "@mui/system";

function Blog3() {
  const theme = useTheme();
  const ServiceCard = ({ title, img }) => (
    <Grid
      item
      xs={12}
      sm={6}
      md={4}
      sx={{

      }}
    >
      <Card
        sx={{
          backgroundColor: "#1C1C1C",
          color: "#FFFFFF",
          borderRadius: 2,
          maxWidth: 697,
          minWidth: 697,
          maxHeight: 180,
          padding: 2,
          border: "#888888 1px solid",
          display: "flex",
          flexDirection: "column",
            [theme.breakpoints.down(1366)]: {
                maxWidth: 550,
                minWidth: 550,
            },
        }}
        className={"mt-2"}
      >
        <CardContent sx={{ flex: 1 }}>
          <Box display="flex" alignItems="center" mb={2}>
            <img src={img} alt="" />
            <Typography variant="h6" component="div">
              <p className={"ml-2"}>{title}</p>
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );

  const ServiceCardSm = ({ title, img }) => (
    <Grid item xs={12} sm={6} md={4}>
      <Card
        sx={{
          backgroundColor: "#1C1C1C",
          color: "#FFFFFF",
          borderRadius: 2,
          maxWidth: 697,
          // minWidth: 697,
          height: 100,
          padding: 2,
          border: "#888888 1px solid",
          display: "flex",
          flexDirection: "column", // Use column direction for flexbox
        }}
        className={"mt-2"}
      >
        <CardContent sx={{ flex: 1 }}>
          <Box display="flex" alignItems="center" mb={2}>
            <img src={img} alt="" width={70} />
            <Typography variant="h6" component="div">
              <p className={"ml-2"} style={{ fontSize: 14 }}>
                {title}
              </p>
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
  return (
    <>
      <Box sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
        <p className={"topic text-bold"}>Our Certification</p>
        <Box sx={{ width: "100%" }}>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <ServiceCard
              title="OffSec Certified Professional (OSCP) - OffSec"
              img={img1}
            />
            <ServiceCard
              title="GIAC Mobile Device Security Analyst (GMOB) - SANS"
              img={img2}
            />
            <ServiceCard
              title="eLearnSecurity Web Application Penetration Tester (eWPT) - INE Security"
              img={img3}
            />
            <ServiceCard
              title="eLearnSecurity Mobile Application Penetration Tester (eMAPT) - INE Security"
              img={img4}
            />
            <ServiceCard
              title="Certified in Cybersecurity (CC) - ISC2"
              img={img5}
            />
            <ServiceCard
              title="Common Vulnerabilities and Exposures (CVE)"
              img={img6}
            />
          </Grid>
        </Box>
      </Box>

      <Box sx={{ display: { sm: "none" } }}>
        <p className={"topic text-bold"} style={{ fontSize: 30 }}>
          Our Certification
        </p>
        <Box sx={{ width: "100%" }}>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <ServiceCardSm
              title="OffSec Certified Professional (OSCP) - OffSec"
              img={img1}
            />
            <ServiceCardSm
              title="GIAC Mobile Device Security Analyst (GMOB) - SANS"
              img={img2}
            />
            <ServiceCardSm
              title="eLearnSecurity Web Application Penetration Tester (eWPT) - INE Security"
              img={img3}
            />
            <ServiceCardSm
              title="eLearnSecurity Mobile Application Penetration Tester (eMAPT) - INE Security"
              img={img4}
            />
            <ServiceCardSm
              title="Certified in Cybersecurity (CC) - ISC2"
              img={img5}
            />
            <ServiceCardSm
              title="Common Vulnerabilities and Exposures (CVE)"
              img={img6}
            />
          </Grid>
        </Box>
      </Box>
    </>
  );
}

export default Blog3;
