import { Button, Collapse } from "@mui/material";
import { Card, CardContent, Typography, Box } from "@mui/material";
import Grid from "@mui/material/Grid2";
import img1 from "../assets/sv/TrainingServices.png";
import img2 from "../assets/sv/Vector-2.png";
import img3 from "../assets/sv/1.png";
import img4 from "../assets/sv/2.png";
import { useTheme } from "@mui/system";
import { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

function Blog2() {
  const theme = useTheme();
  const [servicesActive, setServicesActive] = useState("1");
  const ServiceCard = ({ title, description, img }) => {
    const [hover, setHover] = useState(false);

    return (
      <Grid item xs={12} sm={6} md={4}>
        <Card
          sx={{
            backgroundColor: "#1C1C1C",
            color: "#FFFFFF",
            borderRadius: 2,
            maxWidth: 690,
            minWidth: 690,
            padding: 2,
            border: "#888888 1px solid",
            display: "flex",
            flexDirection: "column",
            transition: "all 0.3s ease",
            [theme.breakpoints.down(1519)]: {
              maxWidth: 500,
              minWidth: 500,
            },
            [theme.breakpoints.down(500)]: {
              maxWidth: 350,
              minWidth: 350
              // backgroundColor: "#000"
              // minWidth: 500,
            },
          }}
          className={"mt-2"}
          // จัดการ hover
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          <Box
            sx={{
              mt: "auto",
              textAlign: "right",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <ExpandMoreIcon
              sx={{ color: "#808080", fontSize: 36, marginLeft: 1 }}
            />
          </Box>
          <CardContent sx={{ flex: 1 }}>
            <Box display="flex" alignItems="center" mb={2}>
              <div
                style={{
                  backgroundColor: "#03624C",
                  borderRadius: 18,
                  width: 90,
                  height: 90,
                  display: "flex",
                }}
              >
                <img src={img} alt="" style={{ margin: "auto" }} />
              </div>
              <Typography variant="h6" component="div">
                <p className={"ml-2"}>{title}</p>
              </Typography>
            </Box>
          </CardContent>

          <Collapse in={hover} timeout="auto" unmountOnExit>
            <CardContent>
              <Typography variant="body2" sx={{ mb: 2 }} style={{ lineHeight: 2 }} className={"description"}>
                {description}
              </Typography>
            </CardContent>
          </Collapse>

          <Box
            sx={{
              mt: "auto",
              textAlign: "right",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <p style={{ color: "#808080", fontSize: 12 }}>Read More</p>
          </Box>
        </Card>
      </Grid>
    );
  };

  return (
    <>
      <p className={"topic text-bold text-center"}>Services</p>
      <Box sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
        <div style={{ display: "flex", gap: "15px" }} className={"mt-2"}>
          <Button
            className={servicesActive === "1" ? "btn-theme-2" : "btn-theme-1"}
            variant="contained"
            onClick={() => setServicesActive("1")}
          >
            Training Services
          </Button>
          <Button
            className={servicesActive === "2" ? "btn-theme-2" : "btn-theme-1"}
            variant="contained"
            onClick={() => setServicesActive("2")}
          >
            Consulting Services
          </Button>
          <Button
            className={servicesActive === "3" ? "btn-theme-2" : "btn-theme-1"}
            variant="contained"
            onClick={() => setServicesActive("3")}
          >
            Researching Services
          </Button>
        </div>
      </Box>
      <Box sx={{ display: { sm: "none" } }}>
        <div style={{ display: "flex", gap: "15px" }} className={"mt-2"}>
          <Button
            className="btn-theme-2"
            variant="contained"
            style={{ fontSize: 12 }}
          >
            Training Services
          </Button>
          <Button
            className="btn-theme-1"
            variant="contained"
            style={{ fontSize: 12 }}
          >
            Consulting Services
          </Button>
        </div>
        <div className={"mt-1 "}>
          <Button
            className="btn-theme-1"
            variant="contained"
            style={{ fontSize: 12 }}
          >
            Researching Services
          </Button>
        </div>
      </Box>
      <Box sx={{ width: "100%" }}>
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <ServiceCard
            title={
              servicesActive === "1"
                ? "Security Awareness Training"
                : servicesActive === "2"
                  ? "IT Security Risk Assessment and Management"
                  : "Threat Intelligence and Analysis"
            }
            description={
              servicesActive === "1"
                ? "Our Security Awareness Training programs are designed for all levels within your organization, from non-IT staff to management. We provide practical training that helps employees recognize and respond to potential security threats effectively, ensuring your organization remains vigilant against cyber risks."
                : servicesActive === "2"
                  ? "Our IT Security Risk Assessment and Management service provides a thorough evaluation of your organization’s current cybersecurity posture. We identify vulnerabilities, assess risks, and provide actionable recommendations to mitigate potential threats. Our team works closely with you to develop a risk management plan tailored to your business, ensuring your security measures align with industry best practices and regulatory requirements."
                  : "Our Threat Intelligence and Analysis service focuses on researching and identifying emerging cyber threats, vulnerabilities, and attack vectors. We monitor dark web forums, hacker communities, and other intelligence sources to provide up-to-date insights into potential risks targeting your industry. This research helps your organization stay proactive in defending against new and evolving cyber threats."
            }
            img={img1}
          />
          <ServiceCard
            title={
              servicesActive === "1"
                ? "Technical Training"
                : servicesActive === "2"
                  ? "Incident Response Planning and Support"
                  : "Regulatory and Compliance Research"
            }
            description={
              servicesActive === "1"
                ? "Our Technical Training courses cover advanced topics such as Penetration Testing and Digital Forensics and Incident Response (DFIR). These hands-on sessions are tailored to your IT teams, equipping them with the skills needed to safeguard your organization against sophisticated threats."
                : servicesActive === "2"
                  ? "Our Incident Response Planning and Support service helps your organization develop a comprehensive incident response strategy. We assist in creating a tailored plan that includes threat detection, containment, eradication, and recovery processes. Additionally, our team offers on-demand support during and after security incidents, ensuring that your organization can respond effectively and minimize the impact of cyberattacks."
                  : "Our Regulatory and Compliance Research service provides organizations with up-to-date analysis on evolving cybersecurity regulations and compliance standards. We keep track of changes in laws like GDPR, HIPAA, and CCPA, ensuring that your organization remains compliant with current and future legal requirements. This service is crucial for businesses operating in regulated industries, helping them avoid legal and financial repercussions."
            }
            img={img2}
          />
          <ServiceCard
            title={
              servicesActive === "1"
                ? "Cybersecurity Team Building"
                : servicesActive === "2"
                  ? "Cybersecurity Strategy and Roadmap Development"
                  : "Emerging Technology Risk Research"
            }
            description={
              servicesActive === "1"
                ? "Build a strong, cohesive cybersecurity team with our specialized workshops. We focus on enhancing team dynamics, problem-solving skills, and effective communication during critical incidents, helping your organization respond swiftly and effectively to any cybersecurity challenge."
                : servicesActive === "2"
                  ? "We help organizations design a long-term cybersecurity strategy aligned with business goals. Our experts work with your leadership team to create a tailored cybersecurity roadmap that prioritizes investments, resources, and initiatives, ensuring your organization stays ahead of evolving threats while optimizing budget and operational efficiency."
                  : "Our Emerging Technology Risk Research focuses on analyzing the security risks associated with new and emerging technologies such as IoT, 5G, and edge computing. We explore potential vulnerabilities and security challenges that these technologies may introduce to your organization, providing insights into how to integrate them securely and mitigate associated risks."
            }
            img={img3}
          />
          <ServiceCard
            title={
              servicesActive === "1"
                ? "Cloud Security Training"
                : servicesActive === "2"
                  ? "Third-Party Vendor Security Assessment"
                  : "Data Breach and Incident Research"
            }
            description={
              servicesActive === "1"
                ? "Our Cloud Security Training is designed to help your organization secure cloud environments. This course covers cloud-specific security practices, such as configuring secure access, managing cloud infrastructure, and defending against cloud-based threats. With the growing adoption of cloud services, this training is essential for organizations looking to protect their data and applications hosted in platforms like AWS, Azure, or Google Cloud."
                : servicesActive === "2"
                  ? "Our Third-Party Vendor Security Assessment service ensures that the external vendors and partners you work with comply with your security standards. We assess their cybersecurity practices, policies, and systems to identify potential risks associated with third-party access to your data and systems. This service is crucial for organizations relying on external vendors for critical services or handling sensitive information."
                  : "We specialize in researching past data breaches and cybersecurity incidents, studying patterns, root causes, and the methods used by attackers. This research helps organizations learn from previous incidents across the industry, applying those lessons to improve their own security defenses. Our service is essential for businesses looking to understand and prepare for potential threats based on real-world scenarios."
            }
            img={img4}
          />
        </Grid>
      </Box>
    </>
  );
}

export default Blog2;
