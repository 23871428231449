import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";
import Img from "../assets/Vector.png";
import { Button } from "@mui/material";

function Blog1({ scrollToSection, servicesRef, contactRef, aboutRef }) {
  return (
    <Box sx={{ flexGrow: 1, display: "flex", justifyContent: "center" }}>
      <Grid container spacing={2} style={{ marginTop: "200px" }}>
        <Grid
          item
          xs={12}
          md={4}
          sx={{ display: { xs: "none", sm: "none", md: "block" } }}
        >
          <img src={Img} alt="logo" style={{ width: "100%" }} />
        </Grid>

        <Grid
          item
          xs={12}
          md={8}
          sx={{
            textAlign: "left",
            display: { xs: "none", sm: "none", md: "block" },
          }}
          className={"ml-5"}
        >
          <p className="topic text-bold">Our Mission is to Make Your</p>
          <p className="topic text-bold">Business More Secure.</p>
          <br />
          <p>
            IC21 Tech Co., Ltd. specializes in comprehensive IT solutions,
            cybersecurity
          </p>
          <p>
            training, and innovative research to keep your business ahead of the
            curve.
          </p>
          <br />
          <Box sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
              <Button
                className="btn-theme-1"
                variant="contained"
                onClick={() => scrollToSection(servicesRef)}
              >
                Explore Our Services
              </Button>
              <Button
                className="btn-theme-1"
                variant="contained"
                onClick={() => scrollToSection(contactRef)}
              >
                Request a Consultation
              </Button>
              <Button
                className="btn-theme-1"
                variant="contained"
                onClick={() => scrollToSection(aboutRef)}
              >
                Learn More About Us
              </Button>
            </Box>
          </Box>
          <Box sx={{ display: { sm: "none" } }}>
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
              <Button
                className="btn-theme-1"
                variant="contained"
                onClick={() => scrollToSection(servicesRef)}
              >
                Explore Our Services
              </Button>
              <Button
                className="btn-theme-1"
                variant="contained"
                onClick={() => scrollToSection(contactRef)}
              >
                Request a Consultation
              </Button>
              <Button
                className="btn-theme-1"
                variant="contained"
                onClick={() => scrollToSection(aboutRef)}
              >
                Learn More About Us
              </Button>
            </Box>
          </Box>
        </Grid>
        

        <Grid
          item
          xs={12}
          md={8}
          sx={{
            textAlign: "left",
            display: {sm: "none"},
          }}
        >
          <p className="topic text-bold" style={{ fontSize: 30 }}>Our Mission is to Make Your</p>
          <p className="topic text-bold" style={{ fontSize: 30 }}>Business More Secure.</p>
          <br />
          <p style={{ fontSize: 20 }}>
            IC21 Tech Co., Ltd. specializes in comprehensive IT solutions,
            cybersecurity
          </p>
          <p style={{ fontSize: 20 }}>
            training, and innovative research to keep your business ahead of the
            curve.
          </p>
          <br />
          <Box sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }} >
              <Button
                className="btn-theme-1"
                variant="contained"
                onClick={() => scrollToSection(servicesRef)}
              >
                Explore Our Services
              </Button>
              <Button
                className="btn-theme-1"
                variant="contained"
                onClick={() => scrollToSection(contactRef)}
              >
                Request a Consultation
              </Button>
              <Button
                className="btn-theme-1"
                variant="contained"
                onClick={() => scrollToSection(aboutRef)}
              >
                Learn More About Us
              </Button>
            </Box>
          </Box>
          <Box sx={{ display: { sm: "none" } }}>
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
              <Button
                className="btn-theme-1"
                variant="contained"
                style={{ fontSize: 10 }}
                onClick={() => scrollToSection(servicesRef)}
              >
                Explore Our Services
              </Button>
              <Button
                className="btn-theme-1"
                variant="contained"
                style={{ fontSize: 10 }}
                onClick={() => scrollToSection(contactRef)}
              >
                Request a Consultation
              </Button>
              <Button
                className="btn-theme-1"
                variant="contained"
                style={{ fontSize: 10 }}
                onClick={() => scrollToSection(aboutRef)}
              >
                Learn More About Us
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Blog1;


// import Box from "@mui/material/Box";
// import Grid from "@mui/material/Grid2";
// import Img from "../assets/Vector.png";
// import { Button } from "@mui/material";

// function Blog1() {
//   return (
//     <Box sx={{ flexGrow: 1 }}>
//       <Grid container spacing={2}>
//         <Grid
//           item
//           xs={12}
//           md={4}
//           sx={{ display: { xs: "none", sm: "none", md: "block" } }}
//         >
//           <img src={Img} alt="logo" style={{ width: "100%" }} />
//         </Grid>

//         <Grid
//           item
//           xs={12}
//           md={8}
//           sx={{
//             textAlign: "left",
//             display: { xs: "none", sm: "none", md: "block" },
//           }}
//           className={"ml-5"}
//         >
//           <p className="topic text-bold">Our Mission is to Make Your</p>
//           <p className="topic text-bold">Business More Secure.</p>
//           <br />
//           <p>
//             IC21 Tech Co., Ltd. specializes in comprehensive IT solutions,
//             cybersecurity
//           </p>
//           <p>
//             training, and innovative research to keep your business ahead of the
//             curve.
//           </p>
//           <br />
//           <Box sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
//             <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
//               <Button className="btn-theme-1" variant="contained">
//                 Explore Our Services
//               </Button>
//               <Button className="btn-theme-1" variant="contained">
//                 Request a Consultation
//               </Button>
//               <Button className="btn-theme-1" variant="contained">
//                 Learn More About Us
//               </Button>
//             </Box>
//           </Box>

//           <Box sx={{ display: { sm: "none" } }}>
//             <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
//               <Button
//                 className="btn-theme-1"
//                 variant="contained"
//                 style={{ fontSize: 10 }}
//               >
//                 Explore Our Services
//               </Button>
//               <Button
//                 className="btn-theme-1"
//                 variant="contained"
//                 style={{ fontSize: 10 }}
//               >
//                 Request a Consultation
//               </Button>
//             </Box>
//             <Box
//               sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}
//               className={"mt-1"}
//             >
//               <Button
//                 className="btn-theme-1"
//                 variant="contained"
//                 style={{ fontSize: 10 }}
//               >
//                 Learn More About Us
//               </Button>
//             </Box>
//           </Box>
//         </Grid>

//         <Grid
//           item
//           xs={12}
//           md={8}
//           sx={{
//             textAlign: "left",
//             display: { sm: "none" },
//           }}
//         >
//           <p className="topic text-bold" style={{ fontSize: 30 }}>
//             Our Mission is to Make Your
//           </p>
//           <p className="topic text-bold" style={{ fontSize: 30 }}>
//             Business More Secure.
//           </p>
//           <br />
//           <p style={{ fontSize: 20 }}>
//             IC21 Tech Co., Ltd. specializes in comprehensive IT solutions,
//             cybersecurity
//           </p>
//           <p style={{ fontSize: 20 }}>
//             training, and innovative research to keep your business ahead of the
//             curve.
//           </p>
//           <br />
//           <Box sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
//             <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
//               <div style={{ display: "flex", gap: 2 }}>
//                 <Button className="btn-theme-1" variant="contained">
//                   Explore Our Services
//                 </Button>
//                 <Button className="btn-theme-1" variant="contained">
//                   Request a Consultation
//                 </Button>
//               </div>
//               <div>
//                 <Button className="btn-theme-1" variant="contained">
//                   Learn More About Us
//                 </Button>
//               </div>
//             </Box>
//           </Box>

//           <Box sx={{ display: { sm: "none" } }}>
//             <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
//               <Button
//                 className="btn-theme-1"
//                 variant="contained"
//                 style={{ fontSize: 10 }}
//               >
//                 Explore Our Services
//               </Button>
//               <Button
//                 className="btn-theme-1"
//                 variant="contained"
//                 style={{ fontSize: 10 }}
//               >
//                 Request a Consultation
//               </Button>
//             </Box>
//             <Box
//               sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}
//               className={"mt-1"}
//             >
//               <Button
//                 className="btn-theme-1"
//                 variant="contained"
//                 style={{ fontSize: 10 }}
//               >
//                 Learn More About Us
//               </Button>
//             </Box>
//           </Box>
//         </Grid>
//       </Grid>
//     </Box>
//   );
// }

// export default Blog1;
