import { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Box,
  Button,
  Drawer,
  List,
  ListItemButton,
  ListItemText,
  Container,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

const Navbar = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [background, setBackground] = useState("transparent");

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setBackground("#333");
      } else {
        setBackground("transparent");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // ตรวจสอบ path ปัจจุบันเพื่อกำหนดค่า link ของ HOME
  const isPrivacyPage = window.location.pathname === "/privacy";

  const menuItems = [
    {
      label: "HOME",
      id: "home",
      link: isPrivacyPage ? "/" : undefined,
    },
    { label: "ABOUT US", id: "about" },
    { label: "SERVICES", id: "services" },
    { label: "ACHIEVEMENT", id: "achievement" },
    // { label: "CLIENT TESTIMONIALS", id: "testimonials" },
    // { label: "BLOG/RESOURCES", id: "blog" },
    { label: "CONTACT", id: "contact" },
    {
      label: "PRIVACY POLICY & TERMS OF SERVICE",
      id: "privacy",
      link: "/privacy",
    },
  ];

  const handleMenuClick = (id, link) => {
    if (link) {
      window.location.href = link; // Redirect ไปยังหน้าที่กำหนด
    } else {
      const element = document.getElementById(id);
      if (element) {
        window.scrollTo({
          top: element.offsetTop,
          behavior: "smooth",
        });
      }
    }
    setDrawerOpen(false); // ปิด drawer เมื่อคลิกเมนู
  };

  return (
    <>
      <AppBar
        position="fixed"
        style={{
          backgroundColor: background,
          boxShadow: "none",
          paddingTop: 30,
        }}
      >
        <Container maxWidth="xl">
          <Toolbar>
            <Typography
              sx={{ display: { sm: "none" } }}
              style={{ color: "#01de81", fontSize: 20 }}
            >
              IC21 tech
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{
                display: { sm: "none" },
                marginLeft: "auto",
                color: "#01de81",
              }}
              onClick={toggleDrawer(true)}
            >
              <MenuIcon />
            </IconButton>
            <Box
              sx={{
                display: { xs: "none", sm: "flex" },
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              {menuItems.map((item) => (
                <Button
                  color="inherit"
                  key={item.id}
                  onClick={() => handleMenuClick(item.id, item.link)}
                >
                  {item.label}
                </Button>
              ))}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>

      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        sx={{ "& .MuiDrawer-paper": { backgroundColor: "#030f0f" } }} // กำหนดสีพื้นหลังที่นี่
      >
        <Container maxWidth="xl">
          <Box
            sx={{ width: 250 }}
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
          >
            <List>
              {menuItems.map((item) => (
                <ListItemButton
                  key={item.id}
                  onClick={() => handleMenuClick(item.id, item.link)}
                >
                  <ListItemText primary={item.label} style={{ color: "#fff" }}/>
                </ListItemButton>
              ))}
            </List>
          </Box>
        </Container>
      </Drawer>
    </>
  );
};

export default Navbar;
