import React from "react";
import { Box, Typography, TextField, Button, Container, useMediaQuery } from "@mui/material";
import { styled } from "@mui/system";
import Grid from "@mui/material/Grid2";
import ImgFooter from "../../assets/Vector.png";
import SendIcon from "@mui/icons-material/Send";

const FooterContainer = styled(Box)({
  backgroundColor: "#0e161a",
  padding: "20px",
  color: "#fff",
});

const FooterLogo = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
});

const FooterLink = styled(Typography)({
  color: "#ccc",
  marginBottom: "8px",
  "&:hover": {
    color: "#00ffcc",
    cursor: "pointer",
  },
});

const Footer = () => {
  const isMediumScreen = useMediaQuery("(max-width:1550px)");

  return (
    <FooterContainer>
      <Container maxWidth="xl">
        <Box sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
          <Grid container spacing={isMediumScreen ? 1 : 4}>
            <Grid item xs={12} md={4} style={{ minWidth: 200 }}>
              <FooterLogo>
                <Typography color={"#01ce78"} variant="h6" component="div">
                  IC21 Tech
                </Typography>
                <br />
                <img
                  src={ImgFooter}
                  width="100px"
                  alt="IC21 Tech"
                  style={{ marginBottom: "10px" }}
                />
              </FooterLogo>
            </Grid>
            <Grid item xs={12} md={4} style={{ minWidth: 300 }}>
              <FooterLink>HOME</FooterLink>
              <FooterLink>ABOUT US</FooterLink>
              <FooterLink>SERVICES</FooterLink>
              <FooterLink>ACHIEVEMENT</FooterLink>
            </Grid>
            <Grid item xs={12} md={4} style={{ minWidth: 450 }}>
              <FooterLink>CLIENT TESTIMONIALS</FooterLink>
              <FooterLink>BLOG/RESOURCES</FooterLink>
              <FooterLink>CONTACT</FooterLink>
              <FooterLink>PRIVACY POLICY & TERMS OF SERVICE</FooterLink>
            </Grid>
            <Grid item xs={12} md={4} style={{ maxWidth: 400 }}>
              <Typography
                variant="h6"
                component="div"
                style={{ marginBottom: "10px" }}
              >
                Subscription Form
              </Typography>
              <Typography
                variant="body2"
                style={{ marginBottom: "10px", color: "#ccc" }}
              >
                "Stay updated with the latest in IT solutions and cybersecurity
                by subscribing to our newsletter."
              </Typography>
              <div style={{ display: "flex" }}>
                <TextField
                  variant="outlined"
                  placeholder="Email"
                  size="small"
                  sx={{
                    backgroundColor: "#333",
                    borderRadius: "4px",
                    input: { color: "#fff" },
                    width: "80%",
                  }}
                />
                <Button
                  variant="contained"
                  sx={{
                    // marginTop: "2px",
                    backgroundColor: "#01de81",
                    "&:hover": {
                      backgroundColor: "#01de81",
                    },
                    width: "fit-content",
                    marginLeft: 3,
                  }}
                  fullWidth
                >
                  <SendIcon style={{ color: "#000" }} />
                </Button>
              </div>
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ display: { sm: "none" } }}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={4}>
              <Typography
                variant="h6"
                component="div"
                style={{ marginBottom: "10px" }}
              >
                Subscription Form
              </Typography>
              <Typography
                variant="body2"
                style={{ marginBottom: "10px", color: "#ccc" }}
              >
                "Stay updated with the latest in IT solutions and cybersecurity
                by subscribing to our newsletter."
              </Typography>
              <div style={{ display: "flex" }}>
                <TextField
                  variant="outlined"
                  placeholder="Email"
                  size="small"
                  sx={{
                    backgroundColor: "#333",
                    borderRadius: "4px",
                    input: { color: "#fff" },
                    width: "80%",
                  }}
                />
                <Button
                  variant="contained"
                  sx={{
                    // marginTop: "2px",
                    backgroundColor: "#01de81",
                    "&:hover": {
                      backgroundColor: "#01de81",
                    },
                    width: "fit-content",
                    marginLeft: 3,
                  }}
                  fullWidth
                >
                  <SendIcon style={{ color: "#000" }} />
                </Button>
              </div>
            </Grid>
            <Grid item xs={12} md={4} style={{ minWidth: 200 }}>
              <FooterLogo>
                <Typography color={"#01ce78"} variant="h6" component="div">
                  IC21 Tech
                </Typography>
              </FooterLogo>
            </Grid>

            <Grid container spacing={2}>
              <Grid size={6}>
                <FooterLink sx={{ fontSize: 10 }}>HOME</FooterLink>
                <FooterLink sx={{ fontSize: 10 }}>ABOUT US</FooterLink>
                <FooterLink sx={{ fontSize: 10 }}>SERVICES</FooterLink>
                <FooterLink sx={{ fontSize: 10 }}>ACHIEVEMENT</FooterLink>
              </Grid>
              <Grid size={6}>
                <FooterLink sx={{ fontSize: 10 }}>
                  CLIENT TESTIMONIALS
                </FooterLink>
                <FooterLink sx={{ fontSize: 10 }}>BLOG/RESOURCES</FooterLink>
                <FooterLink sx={{ fontSize: 10 }}>CONTACT</FooterLink>
                <FooterLink sx={{ fontSize: 10 }}>
                  PRIVACY POLICY & TERMS OF SERVICE
                </FooterLink>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </FooterContainer>
  );
};

export default Footer;
